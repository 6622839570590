import React from "react";
import clsx from "clsx";

import { useAppState } from "@state";

import { Link } from "gatsby";

const isAnchor = u => {
  const regex = /^#/;
  return regex.test(u);
};

const scrollTo = target => {
  if (typeof window !== "undefined") {
    const el = document.getElementById(target.replace(/^#/, ""));
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
};

const AppLink = ({
  to: _url,
  title,
  className: _className,
  children,
  onClick,
  activeClassName,
  style,
  type,
  partiallyActive,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [, dispatch] = useAppState();

  if (
    _url &&
    typeof _url === "string" &&
    type !== "button" &&
    !isAnchor(_url)
  ) {
    const craftUrl = process.env.GATSBY_CRAFT_BASE_URL;
    const siteUrl = process.env.GATSBY_SITE_URL;
    let url = _url.replace(craftUrl, "").replace(siteUrl, "");

    const handleClick = e => {
      // e.preventDefault();
      if (onClick) {
        onClick(e);
      }

      dispatch({
        type: "hideNav",
      });

      // Trigger page transition
      dispatch({
        type: "startTransition",
        to: url,
      });
    };

    const appLinkRegex = /^\/app\//;
    const dataLinkRegex = /^data/;

    // handle internal links
    // usually internal router and/or transition
    if (
      !dataLinkRegex.test(url) &&
      !appLinkRegex.test(url) &&
      type !== "button" &&
      (url.includes(craftUrl) ||
        url.includes(siteUrl) ||
        (!url.includes("https://") &&
          !url.includes("http://") &&
          !url.includes("mailto:") &&
          !url.includes("tel:")))
    ) {
      // Add a preceding slash to any relative urls
      if (url.indexOf("http") === -1 && url.charAt(0) !== "/") {
        url = `/${url}`;
      }
      return (
        <Link
          className={clsx("cursor-pointer", _className)}
          // activeStyle={{ cursor: "default" }}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          title={title}
          name={title}
          to={url}
          onClick={handleClick}
          style={style}
        >
          {children}
        </Link>
      );
    }
    if (url.includes(process.env.GATSBY_OTHER_SITE) && !isAnchor(url)) {
      return (
        <a
          href={url || ""}
          title={title}
          name={title}
          className={clsx("cursor-pointer", _className)}
          onClick={onClick}
          style={style}
        >
          {children}
        </a>
      );
    }
    // handle external links
    // usually open in new tab, noreferer, noopener
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={
          url
            .replace(process.env.GATSBY_ASSET_ROOT, "/assets/")
            .replace(process.env.GATSBY_DOWNLOADS_ROOT, "/downloads/") || ""
        }
        title={title}
        name={title}
        target={appLinkRegex.test(url) ? null : "_blank"}
        rel={appLinkRegex.test(url) ? null : "noopener noreferrer"}
        className={clsx("cursor-pointer", _className)}
        onClick={onClick}
        style={style}
      >
        {children}
      </a>
    );
  }

  // else do a button
  return (
    <button
      type="button"
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
        if (isAnchor(_url)) {
          scrollTo(_url);
        }
      }}
      className={clsx("cursor-pointer", _className)}
      style={style}
    >
      {children}
    </button>
  );
};

AppLink.defaultProps = {
  to: null,
  title: null,
  className: null,
  onClick: null,
  activeClassName: null,
  partiallyActive: true,
};

export default AppLink;
