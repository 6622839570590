import React from "react";
import clsx from "clsx";

const Container = ({
  children,
  className: _className,
  variant,
  padding,
  smallerPadding,
  interior,
  noPadding,
}) => {
  let classes;
  const px = "px-4 sm:px-8 md:px-10 lg:px-12 xl:px-20";
  // if (flush) {
  //   margin = "-mx-3 sm:-mx-6 md:-mx-8 lg:-mx-12";
  // }
  switch (variant) {
    case "2xs":
      classes = "w-full max-w-2xl mx-auto";
      break;
    case "xs":
      classes = "w-full max-w-2xl xl:max-w-3xl mx-auto";
      break;
    case "sm":
      classes = "w-full max-w-4xl xl:max-w-5xl mx-auto";
      break;
    case "xl":
      classes = "w-full max-w-7xl xl:max-w-8xl mx-auto";
      break;
    case "contact":
      classes = "w-full max-w-2xl xl:max-w-3xl mx-auto";
      break;
    default:
      classes = "w-full max-w-5xl xl:max-w-6xl mx-auto";
      break;
  }
  if (noPadding) {
    return (
      <div className={clsx("w-full", classes, _className)}>{children}</div>
    );
  }
  if (smallerPadding) {
    return (
      <div className={clsx("w-full", classes, _className, "px-4 sm:px-8")}>
        {children}
      </div>
    );
  }
  if (padding && !interior) {
    return (
      <div className="px-0 sm:px-6 lg:px-12 xl:px-8">
        <div className={clsx("w-full", classes, _className, px)}>
          {children}
        </div>
      </div>
    );
  }
  if (padding && interior) {
    return (
      <div className="-px-3 sm:px-0">
        <div className={clsx("w-full", classes, _className, px)}>
          {children}
        </div>
      </div>
    );
  }
  return (
    <div className={clsx("w-full", classes, _className, px)}>{children}</div>
  );
};

Container.defaultProps = {
  variant: "md",
  interior: false,
  padding: false,
  className: "",
};

export default Container;
