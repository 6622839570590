import { useEffect, useRef } from "react";
import { useAppState } from "@state";

// This component renders nothing and used to fetch the Hubspot contact and set it to appState

const HubspotContact = () => {
  const [{ hubspot }, dispatch] = useAppState();
  const interval = useRef();

  const handleError = error => {
    // eslint-disable-next-line no-console
    console.log(error);
  };

  const handleSuccess = response => {
    // Set contact to appState if we were able to fetch it. If this is a new user
    // then the contact object will have an `errors` property. In this case we set
    // contact to null.
    const { contact } = response || {};
    const noContact = !contact || (contact.errors && contact.errors.length > 0);
    dispatch({
      type: "setContact",
      contact: noContact ? null : contact,
    });
  };

  // Set up an interval in useEffect to check if hubspot has been initialized.
  // This needs to happen before we make api request to get contact
  useEffect(() => {
    if (!hubspot.contact) {
      if (window._hspb_ran) {
        interval.current = setInterval(() => {
          fetch("/hubspot/contact", {
            method: "POST",
            credentials: "include",
          })
            .then(res => res.json())
            .then(handleSuccess)
            .catch(handleError);
          clearInterval(interval.current);
        }, 200);
      }
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [hubspot.contact]);

  return null;
};

export default HubspotContact;
