const initialState = {
  reasonForContact: "",
  contact: undefined,
  forms: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setForms":
      return {
        ...state,
        forms: action.forms,
      };
    case "setReasonForContact":
      return {
        ...state,
        reasonForContact: action.reasonForContact,
      };
    case "setContact":
      return {
        ...state,
        contact: action.contact,
      };
    case "setFormFields":
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.formId]: action.fields,
        },
      };

    default:
      return state;
  }
};

export default {
  reducer,
  initialState,
};
