import React from "react";
import clsx from "clsx";
import unwidower from "@utils/unwidower";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag,
  dewidow,
}) => {
  let Tag = tag;
  let classes = "";

  switch (variant) {
    case "h1large":
      Tag = "h1";
      classes = "font-sans text-3.5xl sm:text-5.5xl leading-tighter font-bold";
      break;
    case "h1":
      Tag = "h1";
      classes = "font-sans text-2.5xl sm:text-5.5xl leading-tighter font-bold";
      break;
    case "h1small":
      Tag = "h1";
      classes = "font-sans text-2xl sm:text-4xl leading-tighter font-bold";
      break;
    case "h2":
      Tag = "h2";
      classes = "font-sans text-2xl sm:text-5xl leading-tighter font-bold";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-sans text-1.5xl sm:text-4xl leading-tighter font-bold";
      break;
    case "longH3":
      Tag = "h3";
      classes = "font-sans text-1.5xl sm:text-3.5xl leading-tighter font-bold";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-sans text-xl sm:text-3xl leading-tighter font-bold";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-sans text-xl sm:text-2.5xl leading-tight font-bold";
      break;
    case "h6":
      Tag = "h6";
      classes = "font-sans text-xl sm:text-xl leading-tight font-bold";
      break;
    case "h7":
      Tag = "h6";
      classes = "font-sans text-lg leading-tighter font-bold";
      break;
    case "xl":
      classes = "block text-xl leading-normal font-sans";
      break;
    case "lg":
      classes = "block text-lg leading-normal font-sans";
      break;
    case "body":
      classes = "block text-base leading-normal font-sans font-bold";
      break;
    case "sm":
      classes = "block text-sm font-medium leading-normal font-sans";
      break;
    case "xs":
      classes = "block text-xs font-sans leading-tight";
      break;
    case "xxs":
      classes = "block text-xxs font-sans leading-tight";
      break;
    case "label-md":
      classes = "block text-base font-bold leading-none font-sans";
      break;
    case "label":
      classes = "block text-sm font-bold leading-none font-sans";
      break;
    case "metric":
      Tag = "h5";
      classes = "font-sans text-8xl leading-tighter font-bold";
      break;
    case "listLi":
      Tag = "li";
      classes = "block text-xl leading-tight font-sans font-medium";
      break;
    case "caption":
      classes = "block text-sm md:text-xxs font-sans leading-tight";
      break;
    default:
      classes = "";
      break;
  }

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          key={child}
          className={clsx(classes, _className, {
            quotes,
            "prose prose-sm md:prose-base prose-hr:border-t-2 prose-hr:border-gray":
              richText,
          })}
          dangerouslySetInnerHTML={{ __html: unwidower(child, dewidow) }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        key={child}
        className={clsx(classes, _className, {
          quotes,
          "prose prose-sm md:prose-base prose-hr:border-t-2 prose-hr:border-gray":
            richText,
        })}
      >
        {unwidower(child, dewidow)}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: false,
  quotes: false,
  children: null,
  dewidow: false,
};

export default Text;
