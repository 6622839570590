import React from "react";
import clsx from "clsx";

const InputText = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  options,
  className: _className,
  objectTypeId,
}) => {
  const [form, setForm] = formState;

  return (
    <select
      name={name}
      id={name}
      className={clsx(
        "w-full max-w-full border border-gray p-0.5",
        _className,
        {
          "!border-red": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: { objectTypeId, value: e.target.value } });
      }}
    >
      {placeholder && (
        <option key="placeholder" value="">
          {placeholder}
        </option>
      )}
      {options?.map((option, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <option key={i} value={option?.value || option}>
            {option?.label || option}
          </option>
        );
      })}
    </select>
  );
};

InputText.defaultProps = {};

export default InputText;
