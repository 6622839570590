/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React, { useEffect } from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import { useAppState } from "@state";

const PageContainer = ({ children, flex, className, pageContext, meta }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ layout }, dispatch] = useAppState();
  const { path } = pageContext || {};
  const { isTransitioning, hash } = layout;
  // handle scroll effect when visiting cross page anchor links
  useEffect(() => {
    if (!isTransitioning && hash) {
      document.getElementById(hash)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [hash, isTransitioning]);

  const variants = {
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <m.main
      key={path}
      initial="exit"
      animate="enter"
      exit="exit"
      transition={{
        type: "tween",
        ease: "easeInOut",
        duration: 0.333,
      }}
      variants={variants}
      onAnimationComplete={definition => {
        if (definition === "enter") {
          if (typeof window !== "undefined") {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ event: "routeChange" });
            if (window.location?.hash) {
              dispatch({
                type: "setHash",
                hash: window.location.hash.split("#")[1],
              });
            }
          }
          dispatch({
            type: "endTransition",
          });
        }
      }}
      className={clsx(
        className,
        { "flex flex-col": flex },
        "flex-grow overflow-x-clip"
      )}
    >
      {children}
    </m.main>
  );
};

PageContainer.defaultProps = {
  className: "",
  flex: true,
};

export default PageContainer;
