import Cookies from "js-cookie";

const hasGdprConsent = appName => {
  if (typeof window !== "undefined") {
    if (Cookies.get(`${appName}_gdpr`) === "true") {
      // extend consent
      Cookies.set(`${appName}_gdpr`, true, { expires: 365 });
      return true;
    }
  }
  return false;
};

const setGdprConsent = appName => {
  Cookies.set(`${appName}_gdpr`, true, { expires: 365 });
};

export default { hasGdprConsent, setGdprConsent };
