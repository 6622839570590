/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable global-require */

// import styles index
import "./src/pcss/index.pcss";
import { AnimatePresence } from "framer-motion";

import React from "react";
import Default from "@layout/Default";
import ErrorBoundary from "@organisms/ErrorBoundary";
import { AppStateProvider } from "@state";

export const shouldUpdateScroll = () => false;

export const wrapPageElement = ({ element, props }) => {
  const { appPage } = props.pageContext;

  if (appPage) {
    return element;
  }

  return (
    <Default {...props}>
      <AnimatePresence
        mode="wait"
        onExitComplete={() => {
          window.scrollTo({ top: 0 });
        }}
        initial={false}
      >
        {element}
      </AnimatePresence>
    </Default>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <AppStateProvider>{element}</AppStateProvider>
    </ErrorBoundary>
  );
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
};
