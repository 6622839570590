import React from "react";
import clsx from "clsx";

const InputRadio = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  checked,
  value,
  id,
  className: _className,
  objectTypeId,
}) => {
  const [form, setForm] = formState;

  return (
    <input
      type="radio"
      name={name}
      id={id || name}
      value={value}
      placeholder={placeholder}
      className={clsx(_className, {
        "border-red": hasError,
      })}
      onChange={e => {
        setForm({ ...form, [name]: { objectTypeId, value: e.target.value } });
      }}
    />
  );
};

InputRadio.defaultProps = {};

export default InputRadio;
