import React, { useEffect } from "react";
import loadable from "@loadable/component";
import { LazyMotion } from "framer-motion";
import { useAppState } from "@state";
import HubspotContact from "./HubspotContact";

import BrowserWarning from "../atoms/BrowserWarning";

const Modal = loadable(() => import("@organisms/Modal"));
const GdprBanner = loadable(() => import("@organisms/GdprBanner"));
const Header = loadable(() => import("@organisms/Header"));
const Footer = loadable(() => import("@organisms/Footer"));
const Announcement = loadable(() => import("@organisms/Announcement"));
const Userback = process.env.GATSBY_USERBACK_TOKEN
  ? loadable(() => import("@organisms/Userback"))
  : null;

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, ...rest }) => {
  const [, dispatch] = useAppState();
  const { pageContext } = rest;
  const { enableABTests } = pageContext;

  useEffect(() => {
    // load animation features
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
    // determine site version
    if (enableABTests) {
      const test = Math.random() > 0.5;
      // eslint-disable-next-line no-console
      console.log(test ? "B Test" : "A Test");
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "setTest",
        test,
      });
      dispatch({
        type: "setTest",
        test,
      });
    }
  }, []);
  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div id="app_layout">
        <Announcement />
        <Header />
        {children}
        <GdprBanner />
        <Footer />
        <BrowserWarning />
        <Modal />
        <HubspotContact />
        {process.env.GATSBY_USERBACK_TOKEN && <Userback />}
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
