import React from "react";
import clsx from "clsx";

const InputTextarea = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  id,
  className: _className,
  objectTypeId,
}) => {
  const [form, setForm] = formState;

  return (
    <textarea
      name={name}
      id={id || name}
      placeholder={placeholder}
      required={required}
      className={clsx(
        "min-h-[8rem] w-full max-w-full border border-gray p-0.5",
        _className,
        {
          "!border-red": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: { objectTypeId, value: e.target.value } });
      }}
    />
  );
};

InputTextarea.defaultProps = {};

export default InputTextarea;
