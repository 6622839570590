import React from "react";
import { AppLink } from "@base";
import clsx from "clsx";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  dark,
  outline,
}) => {
  const defaultClassName = `flex justify-center items-center cursor-pointer items-center font-bold group leading-none duration-200 rounded-full whitespace-nowrap`;

  const sizes = {
    fluid: "p-2 text-xs",
    xxxs: "p-1 px-2 text-xxs",
    xxs: "p-1.5 px-3 text-xxs",
    xs: "px-5 py-2 text-xxs",
    md: "py-3 px-4 text-sm",
    lg: "px-10 py-5 text-base",
    sm: "py-2.5 px-3.5 text-xs",
  };

  const colors = {
    yellow: "bg-yellow hover:bg-white text-slate",
    black: "bg-black hover:bg-purple text-white",
    red: "bg-pink hover:bg-pink-dark text-white",
    purple: "bg-purple hover:bg-purple text-white",
    white: "bg-white hover:bg-white text-purple",
    transparent: "bg-white hover:bg-white text-slate",
    yellowTransparent: "bg-purple border-yellow text-white",
  };

  const outlineColor =
    {
      purple: "border-purple",
      yellow: "border-yellow hover:border-white group-hover:border-white",
    }[color] || "border-current";

  if (to && to.length > 1) {
    return (
      <span
        className={clsx("group relative inline-flex", {
          "w-full": fullWidth,
        })}
      >
        <LinkObj
          to={to}
          title={title}
          className={clsx(
            defaultClassName,
            sizes[size] || "px-10 py-4 text-sm",
            colors[color] || color,
            "relative z-10 group-hover:-translate-x-0.5 group-hover:-translate-y-0.5",
            {
              "rounded-full": rounded,
              "w-full": fullWidth,
              "border-2": outline,
              [outlineColor]: outline,
            }
          )}
          onClick={onClick}
        >
          <span className="relative z-10 flex text-center">{children}</span>
        </LinkObj>
        <span
          className={clsx(
            defaultClassName,
            "absolute bottom-0 left-1 right-1 top-0 z-0 group-hover:translate-x-1 group-hover:translate-y-1",
            {
              "bg-pink": !dark,
              "bg-white": dark,
              "rounded-full": rounded,
              // "w-full": fullWidth,
            }
          )}
        />
      </span>
    );
  }
  return (
    <span
      className={clsx("group relative inline-flex", {
        "w-full": fullWidth,
      })}
    >
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={clsx(
          defaultClassName,
          sizes[size] || "px-10 py-4 text-sm",
          colors[color] || color,
          "relative z-10 group-hover:-translate-x-1 group-hover:-translate-y-0.5",
          {
            "rounded-full": rounded,
            "w-full": fullWidth,
            "border-2": outline,
            [outlineColor]: outline,
          }
        )}
        onClick={onClick}
      >
        <span className="relative z-10 flex text-center">{children}</span>
      </button>
      <span
        className={clsx(
          defaultClassName,
          "absolute bottom-0 left-1 right-1 top-0 z-0 group-hover:translate-x-1 group-hover:translate-y-1",
          {
            "bg-pink": !dark,
            "bg-white": dark,
            "rounded-full": rounded,
            // "w-full": fullWidth,
          }
        )}
      />
    </span>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "white",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
};

export default Button;
